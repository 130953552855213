import {
  GET_PARSED_QUOTE_FORM,
  GET_PARSED_QUOTE_FORM_DETAILS,
  RESET_BASE_NODE,
  RESET_TOAST_NOTIFICATIONS,
  QUOTE_GETTER,
  LAST_PAYLOAD_GETTER,
} from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import store from '@/Setup/SetupStore.js'
import setupRouter from '@/Setup/Routes/SetupRouter.js'
import { i18n } from '@/Setup/SetupI18n.js'

export const QUOTE_FORM_ROUTE = 'quotes.show'
export const POLICY_FORM_ROUTE = 'policies.show'
export const POLICY_SUPER_EDIT_ROUTE = 'policies.locked.super-edit'
export const FORM_ROUTES = [
  QUOTE_FORM_ROUTE,
  POLICY_FORM_ROUTE,
  POLICY_SUPER_EDIT_ROUTE,
]

export default {
  methods: {
    loadNode(quoteId) {
      store
        .dispatch(`QuoteFormModule/${GET_PARSED_QUOTE_FORM}`, quoteId)
        .catch((error) => {
          this.$errorToast(error.response?.data?.message)
        })
        .finally(() => {
          this.manageNodeAfterIntegration(this.baseNode)
        })
    },

    clean(object) {
      for (let key in object) {
        if (
          object.hasOwnProperty(key) &&
          ([undefined, null, ''].includes(object[key]) ||
            _.isEmpty(object[key]))
        ) {
          delete object[key]
        } else if (typeof object[key] === 'object') {
          this.clean(object[key])
        }
      }

      return object
    },
  },

  beforeRouteEnter(to, from, next) {
    preloadQuote(to)
      .then(() => {
        store.commit(`QuoteFormModule/${RESET_BASE_NODE}`)
        next((vm) => {
          if (FORM_ROUTES.includes(to.name)) {
            vm.loadNode(to.params.quoteId)
          }
        })
      })
      .catch((error) => {
        handleResourceError(error, to, next)
      })
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.quoteId !== from.params.quoteId) {
      preloadQuote(to)
        .then(() => {
          store.commit(`QuoteFormModule/${RESET_BASE_NODE}`)
          if (FORM_ROUTES.includes(to.name)) {
            this.loadNode(to.params.quoteId)
          }
          next()
        })
        .catch((error) => {
          handleResourceError(error, to, next)
        })

      return
    }

    next()
  },

  async beforeRouteLeave(to, from, next) {
    const quote = await store.getters[`QuoteFormModule/${QUOTE_GETTER}`]

    if (
      !FORM_ROUTES.includes(from.name) ||
      ['terminated', 'issued'].includes(quote?.state)
    ) {
      return store
        .dispatch(`QuoteFormModule/${RESET_TOAST_NOTIFICATIONS}`)
        .then(next)
    }

    const payload = await store.getters[
      `QuoteFormModule/${LAST_PAYLOAD_GETTER}`
    ]

    const formData =
      typeof this.getFormData === 'function' ? this.getFormData() : null
    const data = _.get(formData, 'data.data')

    const payloadData = _.get(payload, 'data')
    const mergedFormData = _.assign({}, payloadData, data) // cases when multipleSection has no value in DOM but has key in quoteData API response

    const isEqualData = _.isEqual(
      this.clean(mergedFormData),
      this.clean(payloadData)
    )

    if (
      formData !== null &&
      !isEqualData &&
      !window.confirm(i18n.tc('common.leave-page-confirm'))
    ) {
      return
    }

    return store
      .dispatch(`QuoteFormModule/${RESET_TOAST_NOTIFICATIONS}`)
      .then(next)
  },
}

export function handleResourceError(error, to = {}, next) {
  if (_.isEmpty(error?.message)) {
    next()
  }
  if (error.message.match(/404/i)) {
    next({ name: 'not-found', params: [to.path] })
  }
}

function preloadQuote(to) {
  return store
    .dispatch(
      `QuoteFormModule/${GET_PARSED_QUOTE_FORM_DETAILS}`,
      to.params.quoteId
    )
    .catch((error) => {
      if (!setupRouter.app?.$bvToast) {
        throw error
      }

      setupRouter.app.$bvToast.toast(error.response.data.message, {
        title: i18n.tc('badges.error'),
        variant: 'danger',
        solid: true,
      })
      throw error
    })
}
