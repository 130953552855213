<template>
  <div
    v-if="showMoreByHeight"
    class="reveal-wrapper"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <div
      :style="{
        maxHeight: expanded ? 'none' : `${maxHeight}px`,
        overflow: 'hidden',
        paddingBottom: expanded ? '20px' : '0px',
      }"
      :class="customClass"
      v-html="html"
    />
    <div v-if="!expanded" class="overlay-bottom" />
    <b-button
      variant="light"
      size="sm"
      class="ml-2"
      :class="buttonClass"
      @click="handleShowMoreClick()"
    >
      {{ expanded ? __('common.show-less') : __('common.show-more') }}
    </b-button>
  </div>
  <div v-else>
    <div :class="customClass" v-html="html" />
  </div>
</template>

<script>
export default {
  name: 'ShowMore',

  props: {
    html: {
      type: String,
      default: '',
    },
    maxChar: {
      type: Number,
      default: 300,
    },
    maxHeight: {
      type: Number,
      default: 70,
    },
    customClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      expanded: false,
      buttonClass: '',
      showMoreByHeight: false,
    }
  },

  watch: {
    html(newValue) {
      this.expanded = true
      this.$nextTick(() => {
        this.setShowMoreByHeight()
      })
    },
  },

  mounted() {
    this.setShowMoreByHeight()
  },

  methods: {
    handleShowMoreClick(ev) {
      this.expanded = !this.expanded
    },
    handleMouseOver() {
      this.buttonClass = 'fadde in'
    },
    handleMouseOut() {
      this.buttonClass = 'fadde out'
    },
    setShowMoreByHeight() {
      if (this.$el) {
        const { height } = this.$el.getBoundingClientRect() || {}
        if (height > this.maxHeight) {
          this.showMoreByHeight = true
          this.expanded = false
          return
        }
        this.showMoreByHeight = false
      }
      this.showMoreByHeight = false
    },
  },
}
</script>
<style scoped lang="scss">
.readonly {
  .reveal-wrapper {
    .overlay-bottom {
      background: linear-gradient(
        0deg,
        rgba(247, 248, 249, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
.reveal-wrapper {
  position: relative;
  .btn {
    position: absolute;
    z-index: 1002;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    opacity: 0;

    &.fadde.in {
      opacity: 1;
    }
    &.fadde.out {
      opacity: 0;
    }
  }
  .overlay-bottom {
    position: absolute;
    z-index: 1001;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
</style>
