import { activityRoutes } from '@/Modules/Activity/ActivityRoutes.js'
import { AUTH_USER_GETTER } from '@/Modules/Auth/AuthModule.js'
import { UPDATE_FROM_ROUTE } from '@/Modules/Base/BaseModule.js'
import { authRoutes } from '@/Modules/Auth/AuthRoutes.js'
import { appRoutes } from '@/Modules/Base/AppRoutes.js'
import { binderRoutes } from '@/Modules/Binder/BinderRoutes.js'
import {
  CLIENT_GETTER,
  RESET_CLIENT_MUTATION,
} from '@/Modules/Client/ClientModule.js'
import {
  clientRoutes,
  persistableClientRoutes,
} from '@/Modules/Client/ClientRoutes.js'
import { cssPreviewMode } from '@/Modules/CustomCSS/CustomCSSConstants.js'
import { customCSSRoutes } from '@/Modules/CustomCSS/CustomCSSRoutes.js'
import { datasetRoutes } from '@/Modules/Dataset/DatasetRoutes.js'
import { documentTemplateRoutes } from '@/Modules/DocumentTemplate/DocumentTemplateRoutes.js'
import { emailTemplateRoutes } from '@/Modules/EmailTemplate/EmailTemplateRoutes.js'
import { emailAccountRoutes } from '@/Modules/EmailAccount/EmailAccountRoutes.js'
import { currencyRoutes } from '@/Modules/Currency/CurrencyRoutes.js'
import { integrationRoutes } from '@/Modules/Integration/IntegrationRoutes.js'
import { statementRoutes } from '@/Modules/Statement/StatementRoutes.js'
import { transactionCategoryRoutes } from '@/Modules/Transaction/TransactionCategory/TransactionCategoryRoutes.js'
import { documentTypeRoutes } from '@/Modules/Document/DocumentType/DocumentTypeRoutes.js'
import { endorsementLibrariesRoutes } from '@/Modules/EndorsementLibrary/EndorsementLibraryRoutes.js'
import { entityRoutes } from '@/Modules/Entity/EntityRoutes.js'
import { invoiceRoutes } from '@/Modules/Invoice/InvoiceRoutes.js'
import { paymentRoutes } from '@/Modules/Payment/PaymentRoutes.js'
import { paymentAccountRoutes } from '@/Modules/Payment/PaymentAccount/PaymentAccountRoutes.js'
import { scheduledTaskRoutes } from '@/Modules/ScheduledTask/ScheduledTaskRoutes.js'
import { permissionRoutes } from '@/Modules/Permission/PermissionRoutes.js'
import { productRoutes } from '@/Modules/Product/ProductRoutes.js'
import { profileRoutes } from '@/Modules/Profile/ProfileRoutes.js'
import { counterRoutes } from '@/Modules/Counter/CounterRoutes.js'
import { releaseRoutes } from '@/Modules/Release/ReleaseNotesRoutes.js'
import { claimRoutes } from '@/Modules/Claim/ClaimRoutes.js'
import { inboxRoutes } from '@/Modules/Inbox/InboxRoutes.js'

import {
  BASE_NODE_GETTER,
  QUOTE_GETTER,
  RESET_QUOTE_FORM,
} from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import {
  persistableQuoteRoutes,
  quoteAndPolicyRoutes,
} from '@/Modules/Quote/QuoteAndPolicyRoutes.js'
import { quoteObjectGroupRoutes } from '@/Modules/Quote/QuoteObject/QuoteObjectGroup/QuoteObjectGroupRoutes.js'
import { reportRoutes } from '@/Modules/Report/ReportRoutes.js'
import { roleRoutes } from '@/Modules/Role/RoleRoutes.js'
import {
  RESET_SCHEMA_MUTATION,
  SCHEMA_GETTER,
} from '@/Modules/Schema/SchemaModule.js'
import {
  persistableSchemaRoutes,
  schemaRoutes,
} from '@/Modules/Schema/SchemaRoutes.js'
import { settingRoutes } from '@/Modules/Setting/SettingRoutes.js'
import { userRoutes } from '@/Modules/User/UserRoutes.js'
import { workflowRoutes } from '@/Modules/Workflow/WorkflowRoutes.js'
import store from '@/Setup/SetupStore.js'
import Vue from 'vue'
import Router from 'vue-router'
import { translationLibraryRoutes } from '@/Modules/TranslationLibrary/TranslationLibraryRoutes.js'
import { notificationRoutes } from '@/Modules/Notification/NotificationRoutes.js'
import { exportImportRoutes } from '@/Modules/ExportImport/ExportImportRoutes.js'
import { paymentPlanRoutes } from '@/Modules/Payment/PaymentPlan/PaymentPlanRoutes.js'
import { dataModelRoutes } from '@/Modules/DataModel/DataModelRoutes.js'
import { i18n } from '@/Setup/SetupI18n.js'
import { countryRoutes } from '@/Modules/Country/CountryRoutes.js'
import { paymentTermRoutes } from '@/Modules/Payment/PaymentTerm/PaymentTermRoutes.js'
import { apiLogRoutes } from '@/Modules/ApiLog/ApiLogRoutes.js'
import { videoRoutes } from '@/Modules/Video/VideoRoutes.js'
import { emailPartRoutes } from '@/Modules/EmailPart/EmailPartRoutes.js'
import { endorsementGroupsRoutes } from '@/Modules/EndorsementLibrary/EndorsementGroup/EndorsementGroupRoutes.js'
import { FORM_ROUTES } from '@/Modules/Quote/Mixins/QuoteLoaderMixin.js'
import _ from 'lodash'

export const LS_ROUTE_KEY = 'qb_ls_route_key'

const routesFromForceReload = [...FORM_ROUTES, 'quotes.create']

let routes = []
routes = routes.concat(
  appRoutes,
  entityRoutes,
  binderRoutes,
  userRoutes,
  roleRoutes,
  quoteAndPolicyRoutes,
  quoteObjectGroupRoutes,
  schemaRoutes,
  permissionRoutes,
  workflowRoutes,
  settingRoutes,
  datasetRoutes,
  endorsementLibrariesRoutes,
  productRoutes,
  reportRoutes,
  profileRoutes,
  activityRoutes,
  clientRoutes,
  authRoutes,
  invoiceRoutes,
  paymentRoutes,
  paymentAccountRoutes,
  documentTemplateRoutes,
  emailTemplateRoutes,
  emailPartRoutes,
  currencyRoutes,
  documentTypeRoutes,
  transactionCategoryRoutes,
  scheduledTaskRoutes,
  translationLibraryRoutes,
  counterRoutes,
  releaseRoutes,
  statementRoutes,
  notificationRoutes,
  paymentPlanRoutes,
  exportImportRoutes,
  dataModelRoutes,
  countryRoutes,
  paymentTermRoutes,
  apiLogRoutes,
  integrationRoutes,
  customCSSRoutes,
  videoRoutes,
  endorsementGroupsRoutes,
  claimRoutes,
  emailAccountRoutes,
  inboxRoutes
)

Vue.use(Router)

const setupRouter = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
})

// Middlewares
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware) {
    return context.next
  }

  return () => {
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

export async function handleBeforeEach(to, from, next) {
  if (routesFromForceReload.includes(from.name)) {
    return (window.location.href = to.fullPath)
  }

  if (sessionStorage) {
    if (
      [...authRoutes, ...appRoutes]
        .map((item) => item.name)
        .indexOf(to.name) === -1
    ) {
      try {
        sessionStorage.setItem(LS_ROUTE_KEY, JSON.stringify(to.path))
      } catch (error) {
        console.error(error)
      }
    }
  }
  store.dispatch(`BaseModule/${UPDATE_FROM_ROUTE}`, from)
  // Check permission
  const user = store.getters[`AuthModule/${AUTH_USER_GETTER}`]
  const permissions = (
    Array.isArray(to.meta.permission)
      ? to.meta.permission
      : [to.meta.permission]
  ).filter((permission) => permission !== undefined && permission !== null)
  if (
    permissions?.length > 0 &&
    user &&
    !user.permissions.some((permission) => permissions.includes(permission))
  ) {
    await next('/')
    setupRouter.app.$bvToast.toast(
      i18n.t('error.permission-error', {
        permission: permissions.join(' or '),
      }),
      {
        title: 'Warning!',
        variant: 'warning',
        solid: true,
      }
    )
  }

  if (typeof from.query.lang !== 'undefined') {
    to.query.lang = true
  }

  if (typeof from.query[cssPreviewMode] !== 'undefined') {
    to.query[cssPreviewMode] = true
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router: setupRouter,
      to,
    }

    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
}

setupRouter.beforeEach(async (to, from, next) => {
  return await handleBeforeEach(to, from, next)
})

export function handleAfterEach(to) {
  function getUrlWith(query) {
    if (_.isEmpty(window.location.search)) {
      return window.location + `?${query}`
    }

    return window.location + `&${query}`
  }

  // Persist lang query param if it is enabled on router.
  if (
    typeof to.query.lang !== 'undefined' &&
    !window.location.search.includes('lang')
  ) {
    window.history.replaceState('', '', getUrlWith('lang'))
  }

  // Persist css-preview query param if it is enabled on router.
  if (
    typeof to.query[cssPreviewMode] !== 'undefined' &&
    !window.location.search.includes(cssPreviewMode)
  ) {
    window.history.replaceState('', '', getUrlWith(cssPreviewMode))
  }

  // These are the routes that we would like to persist the quote form state for.
  if (
    !persistableQuoteRoutes.includes(to.name) &&
    (store.getters[`QuoteFormModule/${QUOTE_GETTER}`].id ||
      !_.isEmpty(store.getters[`QuoteFormModule/${BASE_NODE_GETTER}`]))
  ) {
    store.commit(`QuoteFormModule/${RESET_QUOTE_FORM}`)
  }

  // These are the routes that we would like to persist the schema page state for.
  if (
    !persistableSchemaRoutes.includes(to.name) &&
    store.getters[`SchemaModule/${SCHEMA_GETTER}`].id
  ) {
    store.commit(`SchemaModule/${RESET_SCHEMA_MUTATION}`)
  }

  // These are the routes that we would like to persist the client page state for.
  if (
    !persistableClientRoutes.includes(to.name) &&
    store.getters[`ClientModule/${CLIENT_GETTER}`].id
  ) {
    store.commit(`ClientModule/${RESET_CLIENT_MUTATION}`)
  }
}

setupRouter.afterEach((to, from) => {
  handleAfterEach(to)
})

export default setupRouter
