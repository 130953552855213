import QuoteModel from '@/Modules/Quote/QuoteModel.js'
import QuoteService from '@/Modules/Quote/QuoteService.js'
import SchemaModel from '@/Modules/Schema/SchemaModel.js'
import BaseNodeResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/BaseNodeResolver.js'
import QuoteEndorsementModel from '@/Modules/Quote/QuoteEndorsement/QuoteEndorsementModel.js'
import QuoteEndorsementService from '@/Modules/Quote/QuoteEndorsement/QuoteEndorsementService.js'
import QuoteBaseNodeService from '@/Modules/Quote/QuoteBaseNodeService.js'
import _ from 'lodash'

// Getters
export const BASE_NODE_GETTER = 'baseNode'
export const QUOTE_GETTER = 'quote'
export const SCHEMA_GETTER = 'schema'
export const VERSIONS_GETTER = 'versions'
export const TAB_COUNTS_GETTER = 'tabCounts'
export const META_GETTER = 'meta'
export const NOTFICATIONS_GETTER = 'notifications'
export const TOAST_NOTIFICATIONS_GETTER = 'toastNotifications'
export const ENDORSEMENTS_GETTER = 'endorsements'
export const IS_LOADING_GETTER = 'isLoading'
export const ENDORSEMENT_SECTIONS_GETTER = 'endorsementSections'
export const RATING_RULES_KEYS_BY_TYPE_GETTER = _.camelCase(
  'RATING_RULES_KEYS_BY_TYPE_GETTER'
)
export const LAST_PAYLOAD_GETTER = _.camelCase('LAST_PAYLOAD_GETTER')
export const REMOVED_MULTIPLE_SECTION_ROWS_GETTER = _.camelCase(
  'REMOVED_MULTIPLE_SECTION_ROWS_GETTER'
)
export const AI_POPUP_GETTER = _.camelCase('AI_POPUP_GETTER')
export const GET_QUOTE_TEMPORARY_FOLDER = _.camelCase(
  'GET_QUOTE_TEMPORARY_FOLDER'
)

// Mutations
export const SET_BASE_NODE = 'setBaseNode'
export const SET_QUOTE = 'setQuote'
export const SET_SCHEMA = 'setSchema'
export const SET_VERSIONS = 'setVersions'
export const SET_TAB_COUNTS = 'setTabCounts'
export const SET_NOTIFICATIONS = 'setNotifications'
export const RESET_QUOTE_FORM = 'resetQuoteForm'
export const RESET_BASE_NODE = 'resetBaseNode'
export const SET_BASE_NODE_PROPERTY = 'setBaseNodeProperty'
export const SET_META_PROPERTY = 'setMetaProperty'
export const SET_META = 'setMeta'
export const SET_TOAST_NOTIFICATIONS = 'setToastNotifactions'
export const SET_ENDORSEMENTS = 'setEndorsements'
export const SET_IS_LOADING = _.camelCase('SET_IS_LOADING')
export const SET_ENDORSEMENT_SECTIONS = _.camelCase('SET_ENDORSEMENT_SECTIONS')
export const SET_RATING_RULES_KEYS_BY_TYPE = _.camelCase(
  'SET_RATING_RULES_KEYS_BY_TYPE'
)
export const SET_LAST_PAYLOAD = _.camelCase('SET_LAST_PAYLOAD')
export const SET_REMOVED_MULTIPLE_SECTION_ROWS = _.camelCase(
  'SET_REMOVED_MULTIPLE_SECTION_ROWS'
)
export const SET_AI_POPUP = _.camelCase('SET_AI_POPUP')
export const SET_QUOTE_TEMPORARY_FOLDER = _.camelCase(
  'SET_QUOTE_TEMPORARY_FOLDER'
)

// Actions
export const GET_NEW_PARSED_QUOTE_FORM = 'getNewParsedQuoteform'
export const GET_PREVIEW_QUOTE_FORM = 'getPreviewQuoteForm'
export const GET_PARSED_QUOTE_FORM = 'getParsedQuoteForm'
export const GET_SUPER_EDIT_PARSED_QUOTE_FORM = 'getSuperEditParsedQuoteForm'
export const UPDATE_BASE_NODE = 'updateBaseNode'
export const UPDATE_QUOTE = 'updateQuote'
export const UPDATE_MULTI_SECTION_ROWS = 'updateMultiSectionRows'
export const UPDATE_TABLE_SECTION_ROWS = 'updateTableSectionRows'
export const UPDATE_MONEY_TABLE_ROWS = 'updateMoneyTableRows'
export const GET_TAB_COUNT = 'getTabCount'
export const INCREASE_TAB_COUNT = 'increaseTabCount'
export const DECREASE_TAB_COUNT = 'decreaseTabCount'
export const UPDATE_MODAL_NODE = 'updateModalNode'
export const ADD_NOTIFICATION_NODE = 'addNotificationNode'
export const REMOVE_NOTIFICATION_NODE = 'removeNotificationNode'
export const UPDATE_META_PROPERTY = 'updateMetaProperty'
export const ADD_TOAST_NOTIFICATION = 'addToastNotification'
export const RESET_TOAST_NOTIFICATIONS = 'resetToastNotifications'
export const ADD_ENDORSEMENT = 'addEndorsement'
export const SET_ENDORSEMENTS_IN_SECTION = _.camelCase(
  'SET_ENDORSEMENTS_IN_SECTION'
)
export const UPDATE_ENDORSEMENT = 'updateEndorsement'
export const SET_DEFAULT_ENDORSEMENTS = 'setDefaultEndorsements'
export const GET_NEW_PARSED_QUOTE_FORM_DETAILS = _.camelCase(
  'GET_NEW_PARSED_QUOTE_FORM_DETAILS'
)
export const GET_PARSED_QUOTE_FORM_DETAILS = _.camelCase(
  'GET_PARSED_QUOTE_FORM_DETAILS'
)
export const SET_ENDORSEMENT_SECTION = _.camelCase('SET_ENDORSEMENT_SECTION')
export const FETCH_QUOTE_ENDORSEMENTS = _.camelCase('FETCH_QUOTE_ENDORSEMENTS')
export const DELETE_ENDORSEMENT = _.camelCase('DELETE_ENDORSEMENT')
export const DELETE_QUOTE_ENDORSEMENT = _.camelCase('DELETE_QUOTE_ENDORSEMENT')
export const UPDATE_RATING_RULES_RATING_RULES_KEYS_BY_TYPE = _.camelCase(
  'UPDATE_RATING_RULES_RATING_RULES_KEYS_BY_TYPE'
)
export const GET_PARTIAL_QUOTE_FORM = _.camelCase('GET_PARTIAL_QUOTE_FORM')
export const UPDATE_QUOTE_FIELDS = _.camelCase('UPDATE_QUOTE_FIELDS')
export const COPY_ENDORSEMENTS = _.camelCase('COPY_ENDORSEMENTS')
export const REMOVE_MULTIPLE_SECTION_ROW = _.camelCase(
  'REMOVE_MULTIPLE_SECTION_ROW'
)
export const UPDATE_QUOTE_TEMPORARY_FOLDER = _.camelCase(
  'UPDATE_QUOTE_TEMPORARY_FOLDER'
)

export const QuoteFormModule = {
  namespaced: true,

  state: {
    baseNode: {},
    quote: {},
    schema: {},
    versions: [],
    tabCounts: {},
    endorsements: {},
    endorsementSections: {},
    meta: {},
    notifications: {},
    toasts: [],
    isLoading: false,
    ratingRulesKeysByType: {},
    lastPayload: {},
    removedMultipleSectionRows: {},
    aiPopup: null,
    quoteTemporaryFolderIds: {},
  },

  getters: {
    [BASE_NODE_GETTER]: (state) => {
      return state.baseNode
    },

    [QUOTE_GETTER]: (state) => {
      return state.quote
    },

    [SCHEMA_GETTER]: (state) => {
      return state.schema
    },

    [VERSIONS_GETTER]: (state) => {
      return state.versions
    },

    [TAB_COUNTS_GETTER]: (state) => {
      return state.tabCounts
    },

    [META_GETTER]: (state) => {
      return state.meta
    },

    [NOTFICATIONS_GETTER]: (state) => {
      return state.notifications
    },

    [TOAST_NOTIFICATIONS_GETTER]: (state) => {
      return state.toasts
    },

    [ENDORSEMENTS_GETTER]: (state) => {
      return state.endorsements
    },

    [IS_LOADING_GETTER]: (state) => {
      return state.isLoading
    },

    [ENDORSEMENT_SECTIONS_GETTER]: (state) => {
      return state.endorsementSections
    },

    [RATING_RULES_KEYS_BY_TYPE_GETTER]: (state) => {
      return state.ratingRulesKeysByType
    },

    [LAST_PAYLOAD_GETTER]: (state) => {
      return state.lastPayload
    },

    [REMOVED_MULTIPLE_SECTION_ROWS_GETTER]: (state) => {
      return state.removedMultipleSectionRows
    },

    [AI_POPUP_GETTER]: (state) => {
      return state.aiPopup
    },

    [GET_QUOTE_TEMPORARY_FOLDER]: (state) => {
      return state.quoteTemporaryFolderIds
    },
  },

  mutations: {
    [SET_BASE_NODE]: (state, baseNode) => {
      state.baseNode = baseNode
    },

    [SET_QUOTE]: (state, quote) => {
      state.quote = quote
    },

    [SET_SCHEMA]: (state, schema) => {
      state.schema = schema
    },

    [SET_VERSIONS]: (state, versions) => {
      state.versions = versions
    },

    [SET_TAB_COUNTS]: (state, tabCounts) => {
      state.tabCounts = tabCounts
    },

    [SET_NOTIFICATIONS]: (state, notifications) => {
      state.notifications = notifications
    },

    [RESET_BASE_NODE]: (state) => {
      state.baseNode = {}
    },

    [RESET_QUOTE_FORM]: (state) => {
      state.baseNode = {}
      state.quote = {}
      state.schema = {}
      state.versions = {}
      state.tabCounts = {}
      state.endorsements = {}
      state.endorsementSections = {}
      state.meta = {}
      state.lastPayload = {}
      state.ratingRulesKeysByType = {}
      state.removedMultipleSectionRows = {}
    },

    [SET_BASE_NODE_PROPERTY]: (state, { property, value }) => {
      _.set(state.baseNode, `properties.${property}`, value)
    },

    [SET_META_PROPERTY]: (state, { node, property, value }) => {
      if (!state.meta[node]) {
        state.meta[node] = {}
      }

      state.meta[node][property] = value
    },

    [SET_META]: (state, meta) => {
      state.meta = meta
    },

    [SET_TOAST_NOTIFICATIONS]: (state, toasts) => {
      state.toasts = toasts
    },

    [SET_ENDORSEMENTS]: (state, endorsements) => {
      state.endorsements = endorsements
    },

    [SET_IS_LOADING]: (state, value) => {
      state.isLoading = value
    },

    [SET_ENDORSEMENT_SECTIONS]: (state, sections) => {
      state.endorsementSections = sections
    },

    [SET_RATING_RULES_KEYS_BY_TYPE]: (state, value) => {
      state.ratingRulesKeysByType = value
    },

    [SET_LAST_PAYLOAD]: (state, value) => {
      state.lastPayload = value
    },

    [SET_REMOVED_MULTIPLE_SECTION_ROWS]: (state, value) => {
      state.removedMultipleSectionRows = value
    },

    [SET_AI_POPUP]: (state, value) => {
      state.aiPopup = value
    },

    [SET_QUOTE_TEMPORARY_FOLDER]: (state, { quoteId, folderId }) => {
      state.quoteTemporaryFolderIds[quoteId] = folderId
    },
  },

  actions: {
    [GET_NEW_PARSED_QUOTE_FORM]: ({ commit }, reference) => {
      return QuoteService.renderNewQuote(reference)
        .then((response) => {
          commit(SET_BASE_NODE, response.data.baseQuoteFormNode)
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
          throw error
        })
    },

    [GET_PREVIEW_QUOTE_FORM]: ({ commit }, schema_id) => {
      return QuoteService.renderPreviewQuote(schema_id)
        .then((response) => {
          commit(SET_BASE_NODE, response.data.baseQuoteFormNode)
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
          throw error
        })
    },

    [GET_PARSED_QUOTE_FORM]: ({ commit }, quoteId) => {
      commit(SET_IS_LOADING, true)
      return QuoteService.renderQuote(quoteId)
        .then((response) => {
          const baseNode = _.cloneDeep(response.data.baseQuoteFormNode)

          try {
            const service = new QuoteBaseNodeService(baseNode)
            service.applyUiRulesInMultipleSections()
          } catch (error) {
            console.error(error)
          }

          commit(RESET_BASE_NODE)

          commit(SET_BASE_NODE, baseNode)
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
          throw error
        })
    },

    [GET_PARTIAL_QUOTE_FORM]: ({ commit }, quoteId) => {
      commit(SET_IS_LOADING, true)
      return QuoteService.renderPartialForm(quoteId)
        .then(async (response) => {
          const baseNode = _.cloneDeep(response.data.baseQuoteFormNode)
          baseNode.schemaPartName = response?.data?.schemaPartName

          try {
            const service = new QuoteBaseNodeService(baseNode)
            service.applyUiRulesInMultipleSections()
          } catch (error) {
            console.error(error)
          }

          commit(SET_BASE_NODE, baseNode)
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
          throw error
        })
    },

    [GET_SUPER_EDIT_PARSED_QUOTE_FORM]: ({ commit }, quoteId) => {
      commit(SET_IS_LOADING, true)
      return QuoteService.renderSuperEdit(quoteId)
        .then(async (response) => {
          commit(SET_BASE_NODE, response.data.baseQuoteFormNode)
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
        })
    },

    [GET_NEW_PARSED_QUOTE_FORM_DETAILS]: ({ commit }, reference) => {
      commit(SET_IS_LOADING, true)
      return QuoteService.preloadNewQuote(reference)
        .then((response) => {
          commit(
            SET_VERSIONS,
            _.map(response.data.versions, (version) => new QuoteModel(version))
          )
          commit(SET_SCHEMA, new SchemaModel(response.data.schema))
          commit(SET_QUOTE, new QuoteModel(response.data.quote))
          commit(SET_TAB_COUNTS, response.data.tabCounts)
          commit(SET_META, _.toPlainObject(response.data.meta))
          commit(SET_ENDORSEMENTS, {})
          commit(SET_ENDORSEMENT_SECTIONS, {})
          commit(SET_RATING_RULES_KEYS_BY_TYPE, {})
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
          throw error
        })
    },

    [GET_PARSED_QUOTE_FORM_DETAILS]: ({ commit }, quoteId) => {
      commit(SET_IS_LOADING, true)
      return QuoteService.preloadQuote(quoteId)
        .then((response) => {
          commit(
            SET_VERSIONS,
            _.map(response.data.versions, (version) => new QuoteModel(version))
          )
          commit(SET_SCHEMA, new SchemaModel(response.data.schema))
          commit(SET_QUOTE, new QuoteModel(response.data.quote))
          commit(SET_TAB_COUNTS, response.data.tabCounts)
          commit(SET_META, _.toPlainObject(response.data.meta))
          commit(SET_ENDORSEMENTS, {})
          commit(SET_ENDORSEMENT_SECTIONS, {})
          commit(SET_LAST_PAYLOAD, response.data.quote)
          commit(SET_RATING_RULES_KEYS_BY_TYPE, {})
        })
        .catch((error) => {
          console.error(error)
          commit(SET_IS_LOADING, false)
          throw error
        })
    },

    [UPDATE_BASE_NODE]: async ({ commit }, newBaseNode) => {
      commit(SET_BASE_NODE, newBaseNode)
    },

    [UPDATE_QUOTE]: async ({ commit }, newQuote) => {
      commit(SET_QUOTE, newQuote)
    },

    [UPDATE_QUOTE_FIELDS]: ({ commit, state }, data) => {
      if (!data || !state.quote) {
        return
      }

      const newQuote = new QuoteModel({ ...state.quote, ...data })

      commit(SET_QUOTE, newQuote)
    },

    [GET_TAB_COUNT]: ({ commit }, { quoteId, silent }) => {
      return QuoteService.getTabCount(quoteId, { excludeLoader: silent }).then(
        (response) => {
          commit(SET_TAB_COUNTS, response.tabCounts)
        }
      )
    },

    [UPDATE_MULTI_SECTION_ROWS]: ({ commit, state }, { nodePath, rows }) => {
      let currentBaseNode = _.cloneDeep(state.baseNode)
      _.set(
        currentBaseNode,
        `properties.${nodePath?.replace(/\./g, '.properties.')}.rows`,
        rows
      )
      commit(SET_BASE_NODE, _.cloneDeep(currentBaseNode))
    },

    [UPDATE_TABLE_SECTION_ROWS]: ({ commit, state }, { nodePath, rows }) => {
      let currentBaseNode = _.cloneDeep(state.baseNode)
      _.set(
        currentBaseNode,
        `properties.${nodePath}.${BaseNodeResolver.SECTION_TABLE_KEY()}`,
        rows
      )
      commit(SET_BASE_NODE, _.cloneDeep(currentBaseNode))
    },

    [UPDATE_MONEY_TABLE_ROWS]: ({ commit, state }, { nodePath, rows }) => {
      let currentBaseNode = _.cloneDeep(state.baseNode)
      _.set(currentBaseNode, `properties.${nodePath}.rows`, rows)
      commit(SET_BASE_NODE, _.cloneDeep(currentBaseNode))
    },

    [INCREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) + 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    [DECREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) - 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    /**
     * Can be any node update but really use case is only UiTypeModal
     * @param {*} vuex
     * @param {string, object, boolean}
     */
    [UPDATE_MODAL_NODE]: ({ commit, state }, { nodePath, actions, isOpen }) => {
      let currentBaseNode = _.cloneDeep(state.baseNode)
      _.set(
        currentBaseNode,
        `properties.${nodePath?.replace(/\./g, '.properties.')}.actions`,
        actions
      )
      _.set(
        currentBaseNode,
        `properties.${nodePath?.replace(/\./g, '.properties.')}.isOpen`,
        isOpen
      )
      commit(SET_BASE_NODE, _.cloneDeep(currentBaseNode))
    },

    [ADD_NOTIFICATION_NODE]: ({ commit, state }, nodePath) => {
      let notifications = _.cloneDeep(state.notifications)
      if (!notifications[nodePath]) {
        notifications[nodePath] = true
      }
      commit(SET_NOTIFICATIONS, notifications)
    },

    [REMOVE_NOTIFICATION_NODE]: ({ commit, state }, nodePath) => {
      let notifications = _.cloneDeep(state.notifications)
      if (notifications[nodePath]) {
        notifications[nodePath] = false
      }
      commit(SET_NOTIFICATIONS, notifications)
    },

    [UPDATE_META_PROPERTY]: ({ commit }, data) => {
      commit(SET_META_PROPERTY, data)
    },

    [ADD_TOAST_NOTIFICATION]: ({ commit, state }, toast) => {
      const toasts = _.cloneDeep(state.toasts)
      toasts.push(toast)
      commit(SET_TOAST_NOTIFICATIONS, toasts)
    },

    [RESET_TOAST_NOTIFICATIONS]: ({ commit }) => {
      commit(SET_TOAST_NOTIFICATIONS, [])
    },

    [ADD_ENDORSEMENT]: ({ commit, state }, { section, payload }) => {
      const endorsements = _.cloneDeep(state.endorsements)

      if (typeof endorsements[section] === 'undefined') {
        endorsements[section] = []
      }

      endorsements[section].push(payload)
      commit(SET_ENDORSEMENTS, endorsements)
    },

    [SET_ENDORSEMENTS_IN_SECTION]: (
      { commit, state },
      { section, payload }
    ) => {
      const endorsements = _.cloneDeep(state.endorsements)

      endorsements[section] = payload
      commit(SET_ENDORSEMENTS, endorsements)
    },

    [COPY_ENDORSEMENTS]: ({ commit, state }, { section, newSection }) => {
      const endorsements = structuredClone(state.endorsements)

      endorsements[newSection] = structuredClone(endorsements[section])

      commit(SET_ENDORSEMENTS, endorsements)
    },

    [UPDATE_ENDORSEMENT]: ({ commit, state }, { section, key, payload }) => {
      const endorsements = _.cloneDeep(state.endorsements)

      if (typeof endorsements[section] !== 'undefined') {
        endorsements[section][key] = payload
        commit(SET_ENDORSEMENTS, endorsements)
      }
    },

    [DELETE_ENDORSEMENT]: ({ commit, state }, { section, key }) => {
      const endorsements = _.cloneDeep(state.endorsements)

      if (typeof endorsements[section] !== 'undefined') {
        endorsements[section].splice(key, 1)
        commit(SET_ENDORSEMENTS, endorsements)
      }
    },

    [SET_DEFAULT_ENDORSEMENTS]: ({ commit }, endorsements) => {
      let defaultEndorsements = {}

      _.each(endorsements, (section, key) => {
        let index = 1

        defaultEndorsements[key] = _.map(section, (endorsement) => {
          endorsement.index = index
          index++

          return new QuoteEndorsementModel(endorsement)
        })
      })

      commit(SET_ENDORSEMENTS, defaultEndorsements)
    },

    [SET_ENDORSEMENT_SECTION]: ({ commit, state }, { section, nodePath }) => {
      const sections = _.cloneDeep(state.endorsementSections)
      _.set(sections, [section], { nodePath, section })

      commit(SET_ENDORSEMENT_SECTIONS, sections)
    },

    [FETCH_QUOTE_ENDORSEMENTS]: (
      { commit, state },
      { section, nodePath } = {}
    ) => {
      const quoteId = state.quote.id

      if (!section || !nodePath || !quoteId) {
        return
      }

      QuoteEndorsementService.list(quoteId, section)
        .then((endorsements) => {
          const quoteEndorsements = _.cloneDeep(state.endorsements)

          quoteEndorsements[section] = endorsements.map((endorsement) => {
            endorsement.setFrozen(state.quote)
            return endorsement
          })

          commit(SET_ENDORSEMENTS, quoteEndorsements)

          const path = nodePath.replace(/\./g, '.properties.')

          if (_.isEmpty(state.baseNode)) {
            return
          }

          commit(SET_BASE_NODE_PROPERTY, {
            property: `${path}.value`,
            value: endorsements,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },

    [DELETE_QUOTE_ENDORSEMENT]: (
      { commit, state },
      { section, endorsement } = {}
    ) => {
      const quoteId = state.baseNode.quoteId || state.quote.id

      if (!section || !endorsement || !quoteId) {
        return
      }

      return QuoteEndorsementService.delete(quoteId, endorsement.id).then(
        (response) => {
          const endorsements = _.cloneDeep(state.endorsements)

          if (!endorsements[section]) {
            throw new Error('Endorsement section not found')
          }

          const index = endorsements[section].findIndex(
            (item) => item.id === endorsement.id
          )

          if (index === -1) {
            throw new Error('Endorsement id not found')
          }

          endorsements[section].splice(index, 1)
          commit(SET_ENDORSEMENTS, endorsements)

          return response
        }
      )
    },

    [UPDATE_RATING_RULES_RATING_RULES_KEYS_BY_TYPE]: (
      { commit },
      ratingRules
    ) => {
      if (_.isEmpty(ratingRules)) {
        commit(SET_RATING_RULES_KEYS_BY_TYPE, null)
        return
      }

      const keys = _.values(ratingRules).reduce((acc, ratingRule) => {
        if (!ratingRule) {
          return acc
        }

        if (!acc[ratingRule.type]) {
          acc[ratingRule.type] = []
        }

        if (_.isArray(ratingRule.keys)) {
          acc[ratingRule.type] = acc[ratingRule.type].concat(ratingRule.keys)
        }

        if (_.isString(ratingRule.keys)) {
          acc[ratingRule.type].push(ratingRule.keys)
        }

        return acc
      }, {})

      commit(SET_RATING_RULES_KEYS_BY_TYPE, keys)
    },

    [REMOVE_MULTIPLE_SECTION_ROW]: ({ commit, state }, { index, nodePath }) => {
      const newRows = _.isArray(state.removedMultipleSectionRows[nodePath])
        ? state.removedMultipleSectionRows[nodePath]
        : []
      newRows.push(index)

      commit(SET_REMOVED_MULTIPLE_SECTION_ROWS, {
        ...(state.removedMultipleSectionRows || {}),
        [nodePath]: newRows,
      })
    },
  },

  [UPDATE_QUOTE_TEMPORARY_FOLDER]: ({ commit }, { quoteId, folderId }) => {
    commit(SET_QUOTE_TEMPORARY_FOLDER, { quoteId, folderId })
  },
}
