import TraceModeMixin from '@/General/Form/Mixins/TraceModeMixin.js'
import {
  BASE_NODE_GETTER,
  UPDATE_BASE_NODE,
} from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import { UiRulesExecutor } from '@/Modules/Quote/Components/QuoteForm/UiRulesExecutor.js'
import { mapActions, mapGetters } from 'vuex'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'

export default {
  props: {
    node: {
      type: Object,
    },
    columnName: {
      type: String,
      default: '',
    },
    index: {},
    parentNode: {},
    id: {
      type: String,
      default: '',
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    designerMode: {
      type: Boolean,
      default: false,
    },
    multipleSectionKey: {
      type: String,
      default: null,
    },
  },

  mixins: [TraceModeMixin],

  mounted() {
    if (_.isEmpty(this.uiRules)) {
      return
    }

    this.uiRules.forEach((item) => {
      const rule = new UiRule(item)

      if (!rule.trigger) {
        return UiRulesExecutor.uiRuleMustHaveTarget()
      }

      if (!rule.action) {
        return UiRulesExecutor.uiRuleMustHaveAction()
      }

      if (
        (_.isObject(rule.trigger) ? rule.trigger.name : rule.trigger) ===
        UiRulesExecutor.TRIGGER_ONLOAD()
      ) {
        rule.setValue(this.node.value)
        this.$emit('uiRuleRequested', rule, this.node)

        return
      }

      if ([UiRulesExecutor.TRIGGER_ONCHANGE()].includes(rule.trigger)) {
        if (UiRulesExecutor.AVAILABLE_ON_LOAD_ACTIONS().includes(rule.action)) {
          rule.setValue(this.node.value)
          rule.setTrigger(UiRulesExecutor.TRIGGER_ONLOAD())
          this.$emit('uiRuleRequested', rule, this.node)
        }
      }

      if (
        this.$refs[this.snakeKey] &&
        ![UiRulesExecutor.TRIGGER_ONCHANGE()].includes(rule.trigger)
      ) {
        this.$refs[this.snakeKey].$on(rule.trigger, function (value) {
          rule.setValue(value)
          this.$parent.$emit('uiRuleRequested', rule, this.node)
        })
      }
    })

    this.handleUiRulesAfter()
  },

  watch: {
    /**
     * Watcher handles model change from outside the component (in case onChange event does not fire the listener)
     */
    'node.value': {
      handler(newValue, oldValue) {
        if (!_.isArray(this.uiRules) || _.isEqualWith(newValue, oldValue))
          return

        this.uiRules
          .filter(
            (rule) =>
              _.isObject(rule) &&
              [UiRulesExecutor.TRIGGER_ONCHANGE()].includes(rule.trigger)
          )
          .forEach((item) => {
            const rule = new UiRule(item)
            rule.setValue(newValue)
            this.$emit('uiRuleRequested', rule, this.node)
          })

        this.handleUiRulesAfter()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('QuoteFormModule', {
      updateBaseNode: UPDATE_BASE_NODE,
    }),

    applyUiRule(rule, node) {
      this.$emit('uiRuleRequested', rule, node)
    },

    getNodeName(node = this.node) {
      if (_.isEmpty(node) || !node.fieldName) {
        return
      }

      let name = node.fieldName

      if (this.multipleSectionKey && this.index) {
        // multipleSection properties
        name = node.fieldName.replace(
          `[${this.multipleSectionKey}]`,
          `[${this.multipleSectionKey}][${this.index}]`
        )
      }

      if (this.columnName) {
        // moneyTable, tableSection nodes
        name += `[${this.columnName}]`
      }

      return name
    },

    updateBaseNodeFromMeta(meta) {
      if (this.node.uiMapping) {
        let baseNode = _.cloneDeep(this.baseNode)

        _.each(this.node.uiMapping, (value, key) => {
          const nodePath = key.replace(/\./g, '.properties.')
          const clientValue = _.get(meta, value)

          _.set(baseNode.properties, `${nodePath}.value`, clientValue)
        })

        this.updateBaseNode(baseNode)
      }
    },

    /**
     * @param {array} value
     * @returns {array}
     */
    getBaseNodePropertiesByJsonKeys(value = []) {
      if (!_.isArray(value)) return []

      return value
        .map((key) => this.getBaseNodePropertyByJsonKey(key))
        .filter((item) => !!item)
    },

    /**
     * @param {string} value
     * @returns {object|array}
     */
    getBaseNodePropertyByJsonKey(keyValue) {
      const value = keyValue ?? ''

      if (!value.match(/\.{\d}\./)) {
        const key = value.replace(/\./g, '.properties.')
        const property = _.get(this.baseNode.properties, key)

        return property
      }

      const multipleSectionKey = _.first(value.split(/\.{\d}\./))
      const propertyKey = _.last(value.split(/\.{\d}\./))
      const key = multipleSectionKey.replace(/\./g, '.properties.')
      const multipleSection = _.get(this.baseNode.properties, key)

      if (!_.isObject(multipleSection?.rows)) return

      return _.values(multipleSection.rows).map((row) => {
        const property = _.get(row, propertyKey.replace(/\./g, '.properties.'))

        return property
      })
    },

    executeRulesArray(uiRules = [], value) {
      uiRules.forEach((item) => {
        const rule = new UiRule(item)

        if (!rule.trigger) {
          return UiRulesExecutor.uiRuleMustHaveTarget()
        }

        if (!rule.action) {
          return UiRulesExecutor.uiRuleMustHaveAction()
        }

        rule.setValue(value)
        this.$emit('uiRuleRequested', rule)
      })
    },

    getUiRulesGroupedByValueMatch(value, actions = []) {
      const uiRules = this.uiRules
        .filter(
          (item) =>
            actions.includes(item.action) &&
            item.trigger === UiRulesExecutor.TRIGGER_ONCHANGE() &&
            !item.source
        )
        .map((item) => new UiRule(item))

      return uiRules.filter((uiRule) => uiRule.matchParameters(value))
    },

    handleUiRulesAfter() {
      if (_.isEmpty(this.node.uiProperties?.uiRulesAfter)) {
        return
      }

      const { actions } = this.node.uiProperties.uiRulesAfter

      const nodeValue = _.isObject(this.node.value)
        ? this.node.value.value
        : this.node.value

      const uiRulesMatchingNodeValue = this.getUiRulesGroupedByValueMatch(
        nodeValue,
        actions
      )

      if (_.isEmpty(uiRulesMatchingNodeValue)) {
        return
      }

      this.executeRulesArray(uiRulesMatchingNodeValue, nodeValue)
    },
  },

  computed: {
    ...mapGetters('QuoteFormModule', {
      baseNode: BASE_NODE_GETTER,
    }),

    readOnly() {
      return this.uiOptions.readonly || this.uiOptions.disabled
    },

    name() {
      return this.getNodeName(this.node)
    },

    snakeKey() {
      return this.node.dataKey.replace('.', '_')
    },

    uiOptions() {
      return this.node.uiOptions || {}
    },

    isDraggable() {
      return this.designerMode ? 'true' : 'false'
    },

    uiRules() {
      if (_.isEmpty(this.node.uiRules) || !_.isArray(this.node.uiRules)) {
        return []
      }

      return this.node.uiRules.reduce((acc, uiRule) => {
        if (uiRule.group) {
          const groups = _.isArray(uiRule.group) ? uiRule.group : [uiRule.group]
          groups.forEach((group) => {
            let groupUiRules = _.get(this.baseNode.uiRulesGroups, group)

            if (!_.isArray(groupUiRules) || !uiRule.trigger) {
              return acc
            }

            groupUiRules = groupUiRules.map((groupUiRule) => {
              // triggers are overwritten regardless
              groupUiRule.trigger = uiRule.trigger

              // write condition from node uiRule to group rules if not specified
              if (uiRule.condition && !groupUiRule.condition) {
                groupUiRule.condition = uiRule.condition
              }

              // write parameters from node uiRule to group rules if not specified
              if (uiRule.parameters && !groupUiRule.parameters) {
                groupUiRule.parameters = uiRule.parameters
              }

              return groupUiRule
            })

            acc = acc.concat(groupUiRules)
          })

          return acc
        }

        acc.push(uiRule)
        return acc
      }, [])
    },
  },
}
